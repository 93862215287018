import { gql, useQuery, useSubscription } from '@apollo/client';
import { useChat } from 'components/context/ChatContext';
import { Graph } from 'generated/graph';
import { useEffect } from 'react';

const QUERY = gql`
  query partnerSellerList($limit: Int = 100, $offset: Int = 0, $search: String) {
    partnerSellerList(limit: $limit, offset: $offset, search: $search, enabledOnly: true) {
      partnerId
      supplier {
        id
        name
        logo
        origin
        role
      }
      inputDate
      enabled
    }
  }
`;

const QUERY_CHAT_CHANNEL_PARTNER_LIST = gql`
  query chatChannelPartnerList($limit: Int = 10, $offset: Int = 0, $search: String) {
    chatChannelPartnerList(limit: $limit, offset: $offset, search: $search) {
      chatChannel {
        channel
        lastMessageAt
        lastMessage {
          text
          attachments {
            type
            src
          }
        }
        lastMessageAt
        lastSeenMessageAt
        lastMessageEntity {
          entity
          id
          name
          image
        }
        unread
        name
        image
        requiredAdminSupport
        additionEntityInfo {
          entity
          name
          image
        }
      }
      supplier {
        id
        name
        origin
        logo
      }
    }
  }
`;

export const SUBSCRIPTION_CHAT_MESSAGE = gql`
  subscription {
    chatMessageAdded {
      id
      channel
      sender {
        id
        name
        entity
      }
      message {
        text
        attachments {
          type
          src
        }
      }
      createdAt
    }
  }
`;

const QUERY_CHAT_CHANNEL_LIST = gql`
  query chatChannelList($partnerHoldSellerIds: [Int], $phoneNumber: String, $type: ChatType) {
    chatChannelList(partnerHoldSellerIds: $partnerHoldSellerIds, phoneNumber: $phoneNumber, type: $type) {
      name
      image
      channel
      lastMessageAt
      lastMessage {
        text
        attachments {
          type
          src
          lat
          lng
        }
        packageId
        productId
        product {
          id
          code
          price
          title
          thumbnail
          discount
        }
      }
      unread
      lastSeenMessageAt
      lastMessageEntity {
        id
        name
        image
      }
    }
  }
`;

export function usePartner(search: string | null) {
  return useQuery(QUERY, {
    skip: false,
    variables: {
      search,
    },
  });
}

export function useChatList(phone: string | null, newChatMessage: any) {
  const { setCustomerChatCounter } = useChat();
  const partnerQuery = useQuery(QUERY, {
    variables: {
      offset: 0,
      limit: 100000,
    },
  });

  const chatList = useQuery<Graph.Query>(QUERY_CHAT_CHANNEL_LIST, {
    variables: {
      phoneNumber: phone,
      type: 'CUSTOMER',
      partnerHoldSellerIds: partnerQuery.data ? partnerQuery.data.partnerSellerList.map((x: any) => x.supplier.id) : [],
    },
    onCompleted: r => {
      if (r.chatChannelList) {
        setCustomerChatCounter(
          r.chatChannelList.filter(f => (f.unread || 0) > 0).reduce((a, b) => (a = a + (b.unread || 0)), 0),
        );
      }
    },
  });

  useEffect(() => {
    if (newChatMessage) {
      chatList.refetch();
    }
  }, [newChatMessage, chatList]);

  if (chatList.data && partnerQuery.data) {
    return chatList.data.chatChannelList?.map(x => {
      const channel = x.channel?.split('_') || [];
      const id = channel[0].charAt(0) === '2' ? Number(channel[0]) - 2000000000 : Number(channel[1]) - 2000000000;
      const seller = partnerQuery.data.partnerSellerList.find((f: any) => Number(f.supplier.id) === id);
      return {
        ...x,
        supplier: seller ? seller.supplier : { name: '' },
      };
    });
  }
  return [];
}

export function useChatListv2(search: string | null, offset: number, limit?: number, more?: boolean) {
  const { setCustomerChatCounter } = useChat();
  const { loading, refetch, data, fetchMore } = useQuery<Graph.Query>(QUERY_CHAT_CHANNEL_PARTNER_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: limit ? limit : 25,
      offset,
      search,
    },
    onCompleted: res => {
      const count = res.chatChannelPartnerList
        ?.filter((x: any) => x.chatChannel.unread > 0)
        .reduce((a: any, b: any) => (a = a + Number(b.chatChannel.unread || 0)), 0);
      setCustomerChatCounter(count);
    },
  });
  const { data: newChatMessage } = useSubscription<Graph.Subscription>(SUBSCRIPTION_CHAT_MESSAGE);

  // useEffect(() => {
  //   const updateTimer = setInterval(() => {
  //     refetch();
  //   }, 60000);
  //   return () => {
  //     clearInterval(updateTimer);
  //   };
  // }, []);

  useEffect(() => {
    if (newChatMessage) {
      // toaster.notify('You have new message.');
      refetch();
    }
  }, [newChatMessage, refetch]);

  useEffect(() => {
    if (!!more) {
      fetchMore({
        variables: {
          offset: data ? data?.chatChannelPartnerList?.length : 0,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            chatChannelPartnerList: [...prev.chatChannelPartnerList, ...fetchMoreResult.chatChannelPartnerList],
          });
        },
      });
    }
  }, [more]);

  return {
    data: loading ? [] : data?.chatChannelPartnerList,
    fetchMore,
  };
}
