import React, { useContext } from 'react';
import { Graph } from 'generated/graph';

interface UserContextType {
  userId: number;
  superAdmin: boolean;
  admin?: Graph.Admin | null;
}

export const UserContext = React.createContext<UserContextType>({ userId: 0, superAdmin: false });

export function useUser() {
  return useContext(UserContext);
}
