import React from 'react';
import { Route } from 'react-router-dom';
import withTracker from 'components/GoogleAnalytics';
import GeneratedRoute from 'generated/route';

export function createRoute() {
  return (
    <>
      <Route path="/" exact={true} component={withTracker(React.lazy(() => import('pages/index')))} />
      {GeneratedRoute.map(route => {
        return <Route {...route} key={route.path} component={withTracker(route.component)} />;
      })}
    </>
  );
}
