/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext } from 'react';

interface ChatContextType {
  chat?: {
    show: boolean;
    customerChatCounter: number;
  };
  toggle: any;
  setCustomerChatCounter: (e: number) => void;
}

export const ChatContext = React.createContext<ChatContextType>({
  chat: {
    show: false,
    customerChatCounter: 0,
  },
  toggle: () => {},
  setCustomerChatCounter: () => {},
});

export function useChat() {
  return useContext(ChatContext);
}
