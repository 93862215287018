/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, ApolloProvider, gql } from '@apollo/client';
import { ModalProvider } from 'components/provider/ModalProvider';
import { TruckLoading } from 'components/TruckLoading';
import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { UserContext } from './components/context/UserContext';
import { Graph } from './generated/graph';
import { createApolloClient } from './libs/createApolloClient';
import { createRoute } from './libs/createRoute';
import { LoginScreen } from './screens/auth/LoginScreen';
import 'rsuite/dist/rsuite.min.css';
import './scss/main.css';
import './scss/custom.css';
import { ChatContext } from 'components/context/ChatContext';
import { ChatListScreen } from 'screens/chat/ChatListScreen';
import { PolarisProvider } from 'service/PolarisProvider';
import { Icon, Badge } from '@shopify/polaris';
import { ConversationMinor } from '@shopify/polaris-icons';

const RenderChat: React.FC = () => {
  const [chat, setChat] = useState(0);
  const [toggle, setToggle] = useState(false);
  return (
    <ChatContext.Provider
      value={{
        setCustomerChatCounter: setChat,
        chat: {
          show: toggle,
          customerChatCounter: chat,
        },
        toggle: setToggle,
      }}
    >
      <ChatListScreen />
      <div className="Polaris-TopBar-Menu__ActivatorWrapper">
        <div
          // onClick={() => setToggle(!toggle)}
          onClick={() => window.open('/chat')}
          style={{ display: 'flex', position: 'relative', fontSize: '14pt', alignItems: 'center' }}
          className="Polaris-TopBar-Menu__Activator"
        >
          <Icon source={ConversationMinor as any} />
          {chat > 0 && <Badge status="critical">{chat > 10 ? '10+' : chat + ''}</Badge>}
        </div>
      </div>
    </ChatContext.Provider>
  );
};

const RenderApp: React.FC = () => {
  return (
    <Router>
      <PolarisProvider ChatComponent={RenderChat}>
        <div className="all-wrapper with-side-panel solid-bg-all">
          <div className="layout-w">
            {/* <DesktopMenu items={MenuItems} /> */}
            <div className="content-w" style={{ marginLeft: -2 }}>
              {/* <RenderChat/> */}
              <Suspense fallback={<div></div>}>
                <Switch>{createRoute()}</Switch>
              </Suspense>
            </div>
          </div>
        </div>
      </PolarisProvider>
    </Router>
  );
};

function RenderVerify({ verified }: { verified: boolean }) {
  return <TruckLoading />;
}

class App extends React.Component {
  state: {
    token: string | null;
    apollo: ApolloClient<any>;
    user: Graph.Admin;
    verify: boolean;
  };

  constructor(props: any) {
    super(props);
    const token = localStorage.getItem('token');
    this.state = {
      token,
      apollo: createApolloClient(token),
      user: {
        id: 0,
      },
      verify: false,
    };
    this.verifyToken();
  }

  verifyToken = async () => {
    const t = await this.state.apollo.query({
      query: gql`
        {
          admin {
            id
            name
            role
            superAdmin
            permissions
            image
            groupID
            partnerPermission
            recruiterId
          }
        }
      `,
    });

    const data = t.data;

    if (data.admin !== null) {
      if (!data.admin.recruiterId) {
        localStorage.removeItem('token');
        return;
      }
      this.setState({
        user: data.admin as Graph.Admin,
        verify: true,
      });
      localStorage.setItem('token', this.state.token as string);
    } else {
      localStorage.removeItem('token');
      this.setState({
        user: {
          id: 0,
        },
        verify: true,
      });
    }
  };

  saveToken = (token: string) => {
    localStorage.setItem('token', token as string);
    this.setState(
      {
        token,
        apollo: createApolloClient(token),
      },
      () => {
        this.verifyToken();
      },
    );
  };

  render() {
    if (!this.state.verify) {
      return <RenderVerify verified={this.state.verify} />;
    }
    return (
      <ApolloProvider client={this.state.apollo}>
        <UserContext.Provider
          value={{
            userId: this.state.user.id,
            superAdmin: this.state.user.superAdmin ? true : false,
            admin: this.state.user,
          }}
        >
          <ModalProvider>{this.state.token ? <RenderApp /> : <LoginScreen saveToken={this.saveToken} />}</ModalProvider>
        </UserContext.Provider>
      </ApolloProvider>
    );
  }
}

export default App;
