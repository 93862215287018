/* eslint-disable */
import React from 'react'

const GeneratedRoute = [
  { path: '/chat', exact: true, component: React.lazy(() => import('pages/chat/index')) },
  { path: '/customer/bank/transfer', exact: true, component: React.lazy(() => import('pages/customer/bank/transfer')) },
  { path: '/index', exact: true, component: React.lazy(() => import('pages/index')) },
  { path: '/maintenance', exact: true, component: React.lazy(() => import('pages/maintenance/index')) },
  { path: '/marketing/event_campaign/create', exact: true, component: React.lazy(() => import('pages/marketing/event_campaign/$create')) },
  { path: '/marketing/event_campaign/edit/:id', exact: true, component: React.lazy(() => import('pages/marketing/event_campaign/$create')) },
  { path: '/marketing/event_campaign/join/:id', exact: true, component: React.lazy(() => import('pages/marketing/event_campaign/join/[id]')) },
  { path: '/marketing/list', exact: true, component: React.lazy(() => import('pages/marketing/list')) },
  { path: '/order/draft/list', exact: true, component: React.lazy(() => import('pages/order/draft/list')) },
  { path: '/order/draft/preview/:id', exact: true, component: React.lazy(() => import('pages/order/draft/preview/[id]')) },
  { path: '/order/history', exact: true, component: React.lazy(() => import('pages/order/history/index')) },
  { path: '/order/place', exact: true, component: React.lazy(() => import('pages/order/place/index')) },
  { path: '/playground', exact: true, component: React.lazy(() => import('pages/playground/index')) },
  { path: '/setting/advertising', exact: true, component: React.lazy(() => import('pages/setting/advertising')) },
  { path: '/setting/affiliate', exact: true, component: React.lazy(() => import('pages/setting/affiliate')) },
  { path: '/setting/seller', exact: true, component: React.lazy(() => import('pages/setting/seller')) },
];

export default GeneratedRoute
