import { ModalContext, ModalProps } from 'components/context/ModalContext';
import { Dialog } from 'evergreen-ui';
import React, { useState } from 'react';

export const ModalProvider: React.FC = (props: any) => {
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState<ModalProps>();

  const confirm = content?.buttons ? content?.buttons.find(x => x.type === 'Confirm') : undefined;
  const cancel = content?.buttons ? content?.buttons.find(x => x.type === 'Cancel') : undefined;

  const onClose = () => {
    if (cancel) {
      if (cancel.onClick) {
        cancel.onClick();
      }
    }
    setModal(false);
  };

  return (
    <ModalContext.Provider value={{ modal, setModal, content, setContent }}>
      {modal && (
        <Dialog
          isShown={modal}
          hasFooter={!!content?.buttons}
          confirmLabel={confirm ? confirm.text : 'Yes'}
          cancelLabel={cancel ? cancel.text : 'No'}
          onConfirm={confirm ? confirm.onClick : null}
          onCancel={onClose}
          title={content?.title}
          hasCancel={!!cancel}
        >
          {content?.message}
        </Dialog>
      )}
      {props.children}
    </ModalContext.Provider>
  );
};
