/* eslint-disable no-console */
import { gql, useMutation } from '@apollo/client';
import { SendMessageIcon, Tooltip, Icon as IconE } from 'evergreen-ui';
import { Graph } from 'generated/graph';
import React, { useRef, useState } from 'react';
import style from '../style.module.scss';
//@ts-ignore
import { ReactMic } from '@cleandersonlobo/react-mic';
import { AudioChat } from './AudioChat';
import { Icon } from '@shopify/polaris';
import { ImageMajor, MicrophoneMajor, StopMajor } from '@shopify/polaris-icons';

const UPLOAD_FILE = gql`
  mutation Upload($file: Upload!) {
    upload(file: $file) {
      filename
      url
    }
  }
`;

interface Props {
  onSendMessage: (e: { text: string; type: 'TEXT' | 'IMAGE' | 'AUDIO'; src: string }) => void;
}

export function InputChat({ onSendMessage }: Props) {
  const [record, setRecord] = useState(false);
  const refInput = useRef<HTMLInputElement | null>(null);
  const [chatInput, setChatInput] = useState('');
  const [src, setSrc] = useState('');
  const [type, setType] = useState<'TEXT' | 'IMAGE' | 'AUDIO'>('TEXT');
  const [upload] = useMutation<Graph.Mutation>(UPLOAD_FILE, {
    onCompleted: data => {
      setSrc(data.upload.url);
    },
    onError: console.error,
  });

  const onChageInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.validity) {
      if (e.target.files) {
        setType('IMAGE');
        upload({
          variables: {
            file: e.target.files[0],
          },
        });
      }
    }
  };

  const onData = () => {
    console.log('This function does not return an object, but is called at a time interval of 10ms');
  };

  const onStop = (recordedBlob: any) => {
    const file = new File([recordedBlob.blob], new Date() + 'chvoice.wav');
    setType('AUDIO');
    upload({
      variables: {
        file,
      },
    });
  };

  const onSmgSend = () => {
    onSendMessage({
      text: chatInput,
      type,
      src,
    });
    setChatInput('');
    setSrc('');
    setType('TEXT');
  };

  return (
    <div className={style.footer}>
      {type === 'IMAGE' && (
        <div
          style={{
            position: 'absolute',
            top: '-82%',
            backgroundColor: '#fff',
            boxShadow: '0px 2px 24px 0px rgb(0 0 0 / 50%)',
          }}
        >
          <div>
            <img src={src} alt="" style={{ width: 50, height: 50, objectFit: 'cover' }} />
            <i
              className="os-icon os-icon-cancel-circle text-danger"
              style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
              onClick={() => {
                setSrc('');
                setType('TEXT');
              }}
            ></i>
          </div>
        </div>
      )}
      <div className={style.chat_input}>
        {type !== 'AUDIO' ? (
          <input
            className="form-control"
            placeholder="Write somthing..."
            style={{ fontSize: 10 }}
            value={chatInput}
            onChange={e => setChatInput(e.currentTarget.value)}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                if (chatInput) {
                  onSendMessage({
                    text: chatInput,
                    type,
                    src,
                  });
                  setChatInput('');
                  setSrc('');
                  setType('TEXT');
                }
              }
            }}
          />
        ) : (
          <div>
            <AudioChat
              audio={src}
              onCancel={() => {
                setType('TEXT');
                setSrc('');
              }}
            />
          </div>
        )}
        <input type="file" style={{ display: 'none' }} ref={refInput} onChange={onChageInputFile} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 12px' }}>
        <div style={{ display: 'flex' }}>
          <Tooltip content="Image">
            <i style={{ fontSize: 15, marginRight: 10 }} onClick={() => refInput.current && refInput.current.click()}>
              <Icon source={ImageMajor as any} />
            </i>
          </Tooltip>
          <Tooltip content="Audio">
            <i
              style={{ fontSize: 15 }}
              onClick={() => {
                if (!!record) {
                  setRecord(false);
                } else {
                  setRecord(true);
                }
              }}
            >
              <Icon source={(record ? StopMajor : MicrophoneMajor) as any} />
            </i>
          </Tooltip>
          <ReactMic
            record={record}
            className="sound-wave"
            onStop={onStop}
            onData={onData}
            strokeColor="#000000"
            backgroundColor="#FF4081"
          />
        </div>
        <div
          onClick={() => {
            if (chatInput) {
              onSendMessage({
                text: chatInput,
                type,
                src,
              });
              setChatInput('');
              setSrc('');
              setType('TEXT');
            }
          }}
        >
          <Tooltip content="Send">
            <IconE icon={SendMessageIcon} style={{ paddingTop: '3%' }} onClick={onSmgSend} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
