/* eslint-disable react/no-unknown-property */
import React from 'react';

export function TruckLoading() {
  return (
    <div className="loading-place">
      <div className="line-road">
        <div className="truck">
          <img src="/logo_l192b0.png" alt="" />
          <i className="os-icon os-icon-truck"></i>
        </div>
        <svg height="5" width="250">
          <g fill="none">
            <path stroke="black" d="M 0 5 L 350 5" strokeDasharray="20,10,5,5,5,10"></path>
          </g>
        </svg>
      </div>
    </div>
  );
}
