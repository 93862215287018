import React from 'react';

export interface ModalProps {
  title: string;
  buttons: { text: string; type: 'Confirm' | 'Cancel'; onClick: any }[];
  message: any;
}

interface Props {
  modal: any;
  setModal: any;
  content: ModalProps | any;
  setContent: ((e: ModalProps) => void) | any;
}

export const ModalContext = React.createContext<Props>({
  modal: false,
  setModal: null,
  content: null,
  setContent: null,
});
