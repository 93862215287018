/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, gql, useMutation } from '@apollo/client';
import { Button } from 'evergreen-ui';
import React, { useReducer } from 'react';
import producer from 'immer';
import { createApolloClient } from 'libs/createApolloClient';
import { Graph } from 'generated/graph';

type Props = {
  saveToken: (token: string) => void;
};

const MUTATION_LOGIN = gql`
  mutation loginAdministrator($username: String!, $password: String!, $otp: Int) {
    loginAdministrator(username: $username, password: $password, otp: $otp)
  }
`;

type State = {
  isloading: boolean;
  isloggin: boolean;
  isverify: boolean;
  error: string;
  user: Graph.Admin;
};

type Action =
  | { type: 'login' | 'success' | 'reset' }
  | { type: 'failure'; error: string }
  | { type: 'verified'; user: Graph.Admin };

const loginReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'login':
      state.isloading = true;
      state.isloggin = false;
      return;
    case 'success':
      state.isloading = false;
      state.isloggin = true;
      state.isverify = true;
      return;
    case 'failure':
      state.isloading = false;
      state.isloggin = false;
      state.error = action.error;
      return;
    case 'reset':
      state.error = '';
      state.isloggin = false;
      state.isloading = false;
      state.isverify = false;
      return;
    case 'verified':
      state.user = action.user;
      return;
    default:
      return state;
  }
};

const initState: State = {
  isloading: false,
  isloggin: false,
  isverify: false,
  error: '',
  user: {
    id: 0,
  },
};

const loginProducer = producer(loginReducer);

async function verifyAdmin(token: string) {
  const client = createApolloClient(token);

  const { data } = await client.query({
    query: gql`
      {
        admin {
          id
          name
          role
          superAdmin
          permissions
          image
          groupID
        }
      }
    `,
  });

  return data.admin as Graph.Admin;
}

export function LoginScreen(props: Props) {
  const [state, dispatch] = useReducer(loginProducer, initState);
  const { user } = state as State;
  const onCompleted = (data: any) => {
    dispatch({ type: 'success' });
    // props.saveToken(data.loginAdministrator as string);

    setTimeout(async () => {
      const user = await verifyAdmin(data.loginAdministrator as string);
      if (user) {
        dispatch({ type: 'verified', user });

        setTimeout(() => {
          props.saveToken(data.loginAdministrator as string);
          window.location.reload();
        }, 1300);
      }
    }, 1000);
  };

  const onError = (error: ApolloError) => {
    dispatch({ type: 'failure', error: error.message });
    setTimeout(() => {
      dispatch({ type: 'reset' });
    }, 2500);
  };

  const [login, { loading }] = useMutation(MUTATION_LOGIN, { onCompleted, onError });

  const onLoginClicked = (e: any) => {
    e.preventDefault();

    dispatch({ type: 'login' });

    login({
      variables: {
        username: e.target.name.value,
        password: e.target.password.value,
      },
    });
  };

  return (
    <div className="auth-wrapper">
      <div className="all-wrapper menu-side with-pattern">
        {(state as State).isverify ? (
          <div
          // style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, margin: 'auto', width: 300, height: 300 }}
          >
            <div className="user-verify">
              <div className="user-verify-coner">
                <div className="user-verify-blank-coner">
                  <div
                    className={`profile ${user.id > 0 ? 'animation' : ''}`}
                    style={
                      user.id > 0
                        ? { backgroundImage: `url(${user.image})` }
                        : { backgroundImage: 'url(/logo_l192.png)' }
                    }
                  ></div>
                </div>
              </div>
              {user.id <= 0 ? <h5>Verify user login...</h5> : <h5>Welcome {user.name}!</h5>}
            </div>
          </div>
        ) : (
          <div className="auth-box-w">
            <div className="logo-w">
              <h1>L192-Partner</h1>
            </div>
            <h4 className="auth-header">Login Form</h4>
            <form onSubmit={onLoginClicked}>
              {state.error && <div className="alert alert-danger">{state.error}</div>}
              <div className="form-group">
                <label>Username</label>
                <input required type="text" className="form-control" placeholder="Enter your name" name="name" />
                <div className="pre-icon os-icon os-icon-user-male-circle"></div>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  required
                  type="password"
                  className="form-control"
                  placeholder="Enter your password"
                  name="password"
                />
                <div className="pre-icon os-icon os-icon-fingerprint"></div>
              </div>
              <div className="buttons-w">
                <Button appearance="primary" isLoading={loading}>
                  Log me in
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
