import { Tooltip } from 'evergreen-ui';
import React from 'react';
import { CancelMajor } from '@shopify/polaris-icons';
import { Icon } from '@shopify/polaris';

export function AudioChat({ audio, onCancel }: { audio: any; onCancel: any }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <audio src={audio} controls preload="meta" style={{ height: 30, width: '90%' }} />
      <Tooltip content="Cancel">
        <div onClick={onCancel} style={{ fontSize: 15, marginRight: 10 }}>
          <Icon source={CancelMajor as any} />
        </div>
      </Tooltip>
    </div>
  );
}
