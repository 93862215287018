import { useChat } from 'components/context/ChatContext';
import { SideSheet } from 'evergreen-ui';
import React from 'react';
import style from '../style.module.scss';

export function ChatListPanel(props: React.PropsWithChildren<{}>) {
  const { chat, toggle } = useChat();

  return (
    <SideSheet position="right" isShown={!!chat?.show} width={400} preventBodyScrolling onCloseComplete={toggle}>
      <ul className={style.user_list}>{props.children}</ul>
    </SideSheet>
  );
}
