import React, { useState, useCallback, useContext } from 'react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Frame, TopBar } from '@shopify/polaris';
import { UserContext } from 'components/context/UserContext';
import { Link } from 'react-router-dom';
import { DesktopMenu } from 'components/light/DesktopMenu';
import { MenuItems } from 'MenuItem';

const logo = {
  width: 128,
  topBarSource: '/logo.svg',
  contextualSaveBarSource: '/logo.svg',
  accessibilityLabel: 'L192 Partner',
};

export function PolarisProvider(props: React.PropsWithChildren<{ ChatComponent?: any }>) {
  const { ChatComponent } = props;
  const { admin } = useContext(UserContext);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive(mobileNavigationActive => !mobileNavigationActive),
    [],
  );

  const toggleUserMenuActive = useCallback(() => setUserMenuActive(userMenuActive => !userMenuActive), []);

  const userMenu = (
    <TopBar.UserMenu
      name={(admin?.name || '').toUpperCase()}
      detail={admin?.role as string}
      avatar={admin?.image as string}
      open={userMenuActive}
      initials={admin?.name?.substring(0, 1)}
      actions={[
        {
          items: [
            {
              content: 'Logout',
              onAction: () => {
                localStorage.removeItem('token');
                sessionStorage.removeItem('user_chat');
                window.location.reload();
              },
            },
          ],
        },
      ]}
      onToggle={toggleUserMenuActive}
    />
  );

  const topBarMarkup = (
    <TopBar
      secondaryMenu={
        <div>
          <ChatComponent />
        </div>
      }
      userMenu={userMenu}
      showNavigationToggle
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );
  return (
    <AppProvider
      i18n={enTranslations}
      linkComponent={({ children, url, ...rest }: { children?: React.ReactNode; url: string }) => {
        return (
          <Link to={url} {...rest}>
            {children}
          </Link>
        );
      }}
      features={{
        polarisSummerEditions2023: true,
        polarisSummerEditions2023ShadowBevelOptOut: true,
      }}
    >
      <Frame
        logo={logo}
        topBar={topBarMarkup}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        navigation={<DesktopMenu polaris items={MenuItems} />}
      >
        {props.children}
      </Frame>
    </AppProvider>
  );
}
