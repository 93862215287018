import { Avatar, Pill, Tooltip, SearchInput } from 'evergreen-ui';
import React, { useReducer } from 'react';
import style from './style.module.scss';
import producer from 'immer';
import { useChatListv2 } from './components/PartnerList';
import { Graph } from 'generated/graph';
import { ChatListPanel } from './components/ChatListPanel';
import { ChatChannel } from './components/ChatChannel';
import { Button } from '@shopify/polaris';
export interface UserSupplier extends Graph.ChatChannel {
  id: number | 0;
  supplierId: number | 0;
  supplierName: string | '';
  show: boolean | false;
}

interface StateType {
  users: UserSupplier[];
  searchInput: string;
  search: string;
  chatIndex: number;
}

type ActionType =
  | { type: 'select_user'; user: UserSupplier }
  | { type: 'remove' | 'mini' | 'open'; id: number }
  | { type: 'field'; field: any; value: any }
  | { type: 'chat'; index: number };

class storeSession {
  static save(user: UserSupplier[]) {
    sessionStorage.setItem('user_chat', JSON.stringify(user));
  }

  static load() {
    const session = sessionStorage.getItem('user_chat');
    if (session) {
      return JSON.parse(session);
    }
    return [];
  }
}

const chatListReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case 'select_user':
      if (!state.users.find(x => x.id === action.user.id)) {
        state.users.push(action.user);
      }
      storeSession.save(state.users);
      return;
    case 'remove':
      const indexRemove = state.users.findIndex(x => x.id === action.id);
      if (indexRemove >= 0) {
        state.users.splice(indexRemove, 1);
      }
      storeSession.save(state.users);
      return;
    case 'mini':
      const indexMini = state.users.findIndex(x => x.id === action.id);
      if (indexMini >= 0) {
        state.users[indexMini].show = false;
      }
      storeSession.save(state.users);
      return;
    case 'open':
      const indexOpen = state.users.findIndex(x => x.id === action.id);
      if (indexOpen >= 0) {
        state.users[indexOpen].show = true;
      }
      storeSession.save(state.users);
      return;
    case 'field':
      (state as any)[action.field] = action.value;
      return;
    case 'chat':
      state.chatIndex = action.index;
      return;
    default:
      return state;
  }
};

const ChatListProducer = producer(chatListReducer);

const initState = {
  users: storeSession.load(),
  searchInput: '',
  search: '',
  chatIndex: -1,
};

export function ChatListScreen() {
  const [state, dispatch] = useReducer(ChatListProducer, initState);
  useChatListv2(null, 0);
  // const [timeout, setOut] = useState<any>(0);
  // const [hasmore, setHasMore] = useState(true);
  // const { data: newChatMessage } = useSubscription<Graph.Subscription>(SUBSCRIPTION_CHAT_MESSAGE);
  // const partner = usePartner(null);
  // const list = useChatList(state.search ? state.search : null, newChatMessage);

  // useEffect(() => {
  //   if (newChatMessage) {
  //     refetch();
  //   }
  // }, [newChatMessage, refetch]);

  // const channelList = dataChat ? dataChat.chatChannelPartnerList.map((x: any) => x) : [];

  const onFilter = () => {
    // if (timeout) clearTimeout(timeout);
    // setOut(
    //   setTimeout(() => {
    //     dispatch({ type: 'field', field: 'search', value: state.searchInput });
    //   }, 2000),
    // );
  };

  return (
    <React.Fragment>
      <ChatListPanel>
        <li>
          <Button primary fullWidth size="slim" url="/chat" target="_blank">
            Full Screen
          </Button>
          <br />
        </li>
        <li>
          <SearchInput
            type="text"
            value={state.searchInput}
            placeholder="Search seller..."
            onChange={(e: any) => {
              if (e.currentTarget.value === '') {
                dispatch({ type: 'field', field: 'searchInput', value: '' });
                dispatch({ type: 'field', field: 'search', value: '' });
                onFilter();
                return;
              }
              dispatch({ type: 'field', field: 'searchInput', value: e.currentTarget.value });
              onFilter();
            }}
            width={'100%'}
            onKeyDown={(k: any) => {
              if (k.keyCode === 13) {
                dispatch({ type: 'field', field: 'search', value: state.searchInput });
                onFilter();
              }
            }}
          />
        </li>
        {/* {list &&
          list.map((x, i) => {
            return (
              <li key={i} className={style.seller}>
                <div
                  onClick={() => {
                    dispatch({
                      type: 'select_user',
                      user: {
                        ...x,
                        supplierId: x.supplier,
                        supplierName: x.supplier.name,
                        id: Number(x.channel?.split('_')[0]) - 1000000000 + x.supplier.id,
                        show: state.users.length > 3 ? false : true,
                      },
                    });
                  }}
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <div className={style.seller_block}>
                    <Avatar name={x.name} src={x.image || ''} size={30} />
                    <div className={style.info}>
                      <span style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <Text truncate as="span" variant="bodySm">
                          {x.supplier.name}
                        </Text>
                        &nbsp;
                        <span style={{ marginTop: -5 }}>
                          <Text truncate as="span" variant="bodySm">
                            &#128232;
                          </Text>
                        </span>
                        &nbsp;
                        <Text truncate as="span" variant="bodySm">
                          {x.name}
                        </Text>
                      </span>
                      <small className="text-mute">{x.lastMessage?.text}</small>
                    </div>
                  </div>
                  {(x.unread || 0) > 0 && <Pill color="red">{x.unread || 0}</Pill>}
                </div>
              </li>
            );
          })} */}
        {/* {!!hasmore && (
          <li className={style.seller}>
            <div
              onClick={() => {
                fetchMore({
                  variables: {
                    offset: dataChat.chatChannelPartnerList.length,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                      setHasMore(false);
                      return prev;
                    }

                    return Object.assign({}, prev, {
                      chatChannelPartnerList: [
                        ...prev.chatChannelPartnerList,
                        ...fetchMoreResult.chatChannelPartnerList,
                      ],
                    });
                  },
                });
              }}
              style={{ textAlign: 'center' }}
              className="btn btn-sm btn-secondary btn-block"
            >
              Load More...
            </div>
          </li>
        )} */}
      </ChatListPanel>
      <div className={style.user_chat_open}>
        {state.users
          .filter(x => !!x.show)
          .map(x => {
            return (
              <ChatChannel
                key={x.id || 0}
                user={x}
                onMinimize={() => dispatch({ type: 'mini', id: x.id || 0 })}
                onClose={() => dispatch({ type: 'remove', id: x.id || 0 })}
              />
            );
          })}
      </div>
      <div className={style.user_chat_active}>
        {state.users
          .filter(x => !x.show)
          .map(x => {
            return (
              <div className={style.user} key={x.id || ''}>
                <Tooltip content={`${x.name} with supplier ${x.supplierName}`} position="left">
                  <div style={{ position: 'relative' }}>
                    <Pill style={{ position: 'absolute', top: -1, left: -1, zIndex: 1 }} backgroundColor="#e65252">
                      <span className="text-light">{x.unread}</span>
                    </Pill>
                    <Avatar
                      boxShadow="0 2px 4px #0000001a,0 12px 28px #0000001a;"
                      name={x.name}
                      src={x.image || ''}
                      size={40}
                      cursor={'pointer'}
                      onClick={() => {
                        const items = state.users.filter(x => !!x.show);

                        if (items.length >= 3) {
                          dispatch({ type: 'mini', id: items[items.length - 1].id || 0 });
                        }

                        dispatch({ type: 'open', id: x.id || 0 });
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
}
