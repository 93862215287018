import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { useMenuContext } from '../layout/MenuContext';
import { MenuItem } from './MainMenu';
import { Navigation } from '@shopify/polaris';

type Props = {
  items: MenuItem[];
  polaris?: boolean;
};

function MenuGroupItem({ item }: { item: MenuItem }) {
  const location = useLocation();
  const pathname = location.pathname + (location.hash || '');
  const isSelected = pathname === item.to;
  const isChildrenSelected = !!(item.subs || []).flat().find(x => x.to === pathname);
  const [collapsed, setCollapsed] = useState(isSelected || isChildrenSelected);

  const onMenuClicked = () => {
    if (item.subs) {
      setCollapsed(!collapsed);
    }
  };

  if (item.header) {
    return (
      <div>
        <h3>{item.name}</h3>
      </div>
    );
  }

  return (
    <>
      {item.to && item.to !== '#' ? (
        <Link to={item.to} className={isSelected ? 'side-menu-level-1 selected' : 'side-menu-level-1'}>
          {item.icon && <i className={`side-menu-icon ${item.icon}`} />}
          <span>{item.name}</span>
          {item.subs && <i className={collapsed ? 'far fa-angle-down' : 'far fa-angle-right'} />}
        </Link>
      ) : (
        <div onClick={onMenuClicked} className={isSelected ? 'side-menu-level-1 selected' : 'side-menu-level-1'}>
          {item.icon && <i className={`side-menu-icon ${item.icon}`} />}
          <span>{item.name}</span>
          {item.subs && <i className={collapsed ? 'far fa-angle-down' : 'far fa-angle-right'} />}
        </div>
      )}
      {item.subs && collapsed && (
        <ul className="side-menu-level-2">
          {item.subs.flat().map(x => (
            <li key={x.name} className={x.to === pathname ? 'selected' : ''}>
              <Link to={x.to}>{x.name}</Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export function DesktopMenu(props: React.PropsWithChildren<Props>) {
  const { type } = useMenuContext();
  const history = useHistory();
  const [name, setName] = useState(history.location.pathname);

  React.useEffect(() => {
    history.listen((location: any) => {
      setName(location.pathname);
    });
  }, [history]);

  if (type === 'mini') {
    return <div />;
  }

  if (props.polaris) {
    return (
      <Navigation location="/">
        {props.items.map((item, index) => {
          return (
            <Navigation.Section
              key={index}
              title={item.name}
              separator={index > 0}
              items={(item as any).subs?.map((x: any) => {
                return {
                  label: x.name,
                  url: x.to,
                  icon: x.icon,
                  onClick: () => {
                    if (x.to === '/') {
                      history.push('/');
                    }
                  },
                  selected: x.to === name,
                  subNavigationItems: x.subs
                    ? x.subs.map((sub: any) => {
                        return {
                          label: sub.name,
                          url: sub.to,
                        };
                      })
                    : [],
                };
              })}
            />
          );
        })}
      </Navigation>
    );
  }

  return (
    <div className="side-menu">
      <div className="side-menu-content">
        <div className="side-menu-logo" onClick={() => history.push('/')}>
          <img src="/logo_l192.png" alt="" /> L192 Partner
        </div>

        {/* <QuickSearch /> */}
        <br />
        {props.items.map((item, idx) => (
          <MenuGroupItem key={item.name + idx} item={item} />
        ))}
      </div>
    </div>
  );
}
