import { useQuery, useSubscription } from '@apollo/client';
import { Graph } from 'generated/graph';
import gql from 'graphql-tag';
import { useState } from 'react';

const QUERY_SEEN_LIST = gql`
  query chatChannelSeenList($channel: String!) {
    chatChannelSeenList(channel: $channel) {
      entity {
        id
        entity
        name
        image
      }
      lastSeenTimestamp
    }
  }
`;

const SUBSCRIPTION_MESSAGE_SEEN = gql`
  subscription chatMessageSeen($channel: String) {
    chatMessageSeen(channel: $channel) {
      entity {
        entity
        id
        name
        image
      }
      lastSeenTimestamp
    }
  }
`;

export default function useSeenList(channel: string): Graph.ChatChannelSeen[] {
  const [seenList, setSeenList] = useState<Graph.ChatChannelSeen[]>([]);

  useSubscription<Graph.Subscription>(SUBSCRIPTION_MESSAGE_SEEN, {
    variables: { channel },
    onData: ({ data }) => {
      setSeenList(prev => {
        if (data.data?.chatMessageSeen) {
          const seenWithoutNewEntity = prev.filter(x => x.entity?.entity !== data.data?.chatMessageSeen?.entity);
          return [...seenWithoutNewEntity, data.data?.chatMessageSeen];
        }
        return prev;
      });
    },
  });

  useQuery<Graph.Query>(QUERY_SEEN_LIST, {
    variables: { channel },
    onCompleted: data => {
      setSeenList(data.chatChannelSeenList || []);
    },
  });

  return seenList;
}
