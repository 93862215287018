import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { Avatar, CrossIcon, IconButton, MinusIcon, Spinner, Tooltip } from 'evergreen-ui';
import { Graph } from 'generated/graph';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { UserSupplier } from '../ChatListScreen';
import style from '../style.module.scss';
import { InputChat } from './InputChat';
import { SUBSCRIPTION_CHAT_MESSAGE } from './PartnerList';
import useSeenList from './useSeenList';

const QUERY_CHANNEL_CHAT = gql`
  query chatChannel($channel: String!) {
    chatChannel(channel: $channel, partner: true) {
      id
      message {
        text
        productId
        attachments {
          src
          type
        }
        product {
          id
          code
          price
          title
          stock
          discount
          thumbnail
          ratingScore
        }
      }
      createdAt
      sender {
        entity
        id
        name
        image
      }
    }
  }
`;

const MUTATION_SEND_MESSAGE = gql`
  mutation sendMessage($channel: String!, $message: ChatMessageInput!) {
    sendMessage(channel: $channel, message: $message) {
      id
    }
  }
`;

const MARK_AS_SEEN = gql`
  mutation markChannelSeen($channel: String!) {
    markChannelSeen(channel: $channel)
  }
`;

function transformToVisualMessage(items: Graph.ChatMessage[], seenList: Graph.ChatChannelSeen[]) {
  const result = items.map(x => ({ message: x, seen: false }));

  for (const seen of seenList) {
    if (seen.entity?.entity?.substr(0, 1) === '2') continue;
    for (const message of result) {
      // console.log(
      //   message.message.createdAt || 0,
      //   seen.lastSeenTimestamp || 0,
      //   seen.entity?.entity,
      //   (message.message.createdAt || 0) <= (seen.lastSeenTimestamp || 0),
      // );
      if ((message.message.createdAt || 0) <= (seen.lastSeenTimestamp || 0)) message.seen = true;
    }
  }

  return result;
}

export function ChatChannel({
  user,
  onMinimize,
  onClose,
}: {
  user: UserSupplier;
  onMinimize: () => void;
  onClose: () => void;
}) {
  const refScrollToBottom = useRef<HTMLDivElement>(null);
  const [send] = useMutation(MUTATION_SEND_MESSAGE);
  const { data: newChatMessage } = useSubscription<Graph.Subscription>(SUBSCRIPTION_CHAT_MESSAGE);
  const { data, refetch } = useQuery<Graph.Query>(QUERY_CHANNEL_CHAT, { variables: { channel: user.channel || '' } });
  const [markAsSeen] = useMutation<Graph.Mutation>(MARK_AS_SEEN, {
    variables: { channel: user.channel },
    refetchQueries: ['chatChannelPartnerList'],
  });

  const seenList = useSeenList(user.channel || '');

  const chatMessage = [...(data?.chatChannel || [])];
  chatMessage.reverse();
  const messagss = transformToVisualMessage(chatMessage, seenList);

  useEffect(() => {
    markAsSeen();
  }, [markAsSeen]);

  useEffect(() => {
    if (newChatMessage) {
      const added = newChatMessage.chatMessageAdded;
      refetch();
      if (added?.channel === user.channel) {
        refetch();
      }
      if (added?.sender?.entity === user.channel?.split('_')[0]) {
        markAsSeen();
      }
    }
  }, [newChatMessage, user, refetch, markAsSeen]);

  useEffect(() => {
    if (messagss.length > 0) {
      refScrollToBottom.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messagss, refScrollToBottom]);

  const onSendMessage = (val: { text: string; type: 'TEXT' | 'IMAGE' | 'AUDIO'; src: string }) => {
    switch (val.type) {
      case 'TEXT':
        send({
          variables: {
            channel: user.channel,
            message: {
              text: val.text,
            },
          },
        })
          .then()
          .catch(console.error);
        break;
      case 'IMAGE':
        send({
          variables: {
            channel: user.channel,
            message: {
              text: val.text,
              attachments: [
                {
                  src: val.src,
                  type: val.type as Graph.AttachmentType,
                },
              ],
            },
          },
        })
          .then()
          .catch(console.error);
        break;
      case 'AUDIO':
        send({
          variables: {
            channel: user.channel,
            message: {
              attachments: [{ type: 'AUDIO', src: val.src }],
            },
          },
        });
    }
  };

  return (
    <div className={style.user_chat}>
      <div className={style.header}>
        <div className={style.profile}>
          <Avatar name={user.name} src={user.image || ''} size={40} />
          <div style={{ marginLeft: 10 }}>
            <span>{user.name}</span>
            <br />
            <small className="text-mute">chat with supplier {user.supplierName}</small>
          </div>
        </div>
        <div className={style.button_group}>
          <Tooltip content="Minimize Chat" position="top">
            <IconButton
              onClick={onMinimize}
              icon={MinusIcon}
              intent="primary"
              appearance="primary"
              marginRight={5}
              size="small"
              borderRadius="50%"
            />
          </Tooltip>
          <Tooltip content="Close Chat" position="top">
            <IconButton
              onClick={onClose}
              icon={CrossIcon}
              intent="danger"
              appearance="primary"
              size="small"
              borderRadius="50%"
            />
          </Tooltip>
        </div>
      </div>
      <div className={style.body}>
        {messagss.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner size={16} />
            <small className="ml-2">Loading...</small>
          </div>
        )}
        {messagss.length > 0 &&
          messagss.map((x, index: number) => {
            return (
              <div
                key={index}
                className={x.message.sender?.entity?.substring(0, 1) === '1' ? style.message : style.message_self}
              >
                {x.message.message?.attachments && x.message.message.attachments[0].type === 'AUDIO' && (
                  <div className={style.card}>
                    <audio
                      key={x.message.message.attachments[0].src + ''}
                      src={x.message.message.attachments[0].src || ''}
                      controls
                      style={{ height: 30, width: 210 }}
                    />
                  </div>
                )}
                {x.message.message?.attachments && x.message.message.attachments[0].type === 'IMAGE' && (
                  <div className={style.card}>
                    <a href={x.message.message.attachments[0].src + ''} target="_blank" rel="noopener noreferrer">
                      <img src={x.message.message.attachments[0].src || ''} alt="" />
                    </a>
                    {x.message.message.text && <div style={{ padding: '0 10px' }}>{x.message.message?.text || ''}</div>}
                    {x.seen && (
                      <div style={{ textAlign: 'right', paddingRight: 10 }}>
                        <span style={{ fontSize: 10 }}>
                          {moment(new Date(x.message.createdAt || '')).format('HH:mm')}{' '}
                        </span>
                        {/* {x.seen ? <Icon source={TickSmallMinor as any} color='base' /> : <div><Icon source={TickSmallMinor as any} color='base' /><Icon source={TickSmallMinor as any} color='base' /></div>} */}
                      </div>
                    )}
                  </div>
                )}
                {x.message.message?.productId && (
                  <div className={style.card}>
                    <a
                      style={{ display: 'flex' }}
                      href={`https://www.l192.com/product/${x.message.message.productId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={x.message.message.product?.thumbnail || ''}
                        alt=""
                        style={{ width: 50, height: 50, objectFit: 'cover', marginRight: '5px' }}
                      />
                      <div style={{ padding: 5 }}>
                        <small
                          style={{
                            width: 145,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                          }}
                        >
                          {x.message.message.product?.title}
                        </small>
                        <small>L192: {x.message.message.product?.code}</small>
                      </div>
                    </a>
                    {x.message.message.text && (
                      <>
                        <hr className="mt-1 mb-1" />
                        <div className="pl-1 pr-1">
                          <span>{x.message.message.text}</span>
                        </div>
                      </>
                    )}
                    {x.seen && (
                      <div style={{ textAlign: 'right' }}>
                        <span style={{ fontSize: 10 }}>
                          {moment(new Date(x.message.createdAt || '')).format('HH:mm')}{' '}
                        </span>
                        {/* <span>{x.seen ? <Icon source={TickSmallMinor as any} color='base' /> : <div><Icon source={TickSmallMinor as any} color='base' /><Icon source={TickSmallMinor as any} color='base' /></div>}</span> */}
                      </div>
                    )}
                  </div>
                )}
                {!x.message.message?.attachments && !x.message.message?.productId && (
                  <div className={style.message_content}>
                    {x.message.message?.text || ''}
                    {x.seen && (
                      <div style={{ textAlign: 'right' }}>
                        <span style={{ fontSize: 10 }}>
                          {moment(new Date(x.message.createdAt || '')).format('HH:mm')}{' '}
                        </span>
                        {/* <span>
                          {x.seen ? <Icon source={TickSmallMinor as any} color='base' /> : <div><Icon source={TickSmallMinor as any} color='base' /><Icon source={TickSmallMinor as any} color='base' /></div>}
                        </span> */}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        <div ref={refScrollToBottom}></div>
      </div>
      <InputChat onSendMessage={onSendMessage} />
    </div>
  );
}
