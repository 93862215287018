import { MenuItem } from './components/light/MainMenu';
import {
  OnlineStoreMinor,
  NoteMinor,
  OrderStatusMinor,
  MoneyMinor,
  StoreMinor,
  AnalyticsMinor,
  DiscountsMinor,
} from '@shopify/polaris-icons';

export const MenuItems: MenuItem[] = [
  {
    name: 'Dashboard',
    to: '#',
    header: true,
    subs: [
      {
        name: 'Home',
        to: '/',
        icon: AnalyticsMinor,
      },
    ],
  },
  {
    name: 'Order',
    to: '#',
    header: true,
    subs: [
      {
        name: 'Placing Order',
        to: '/order/place',
        icon: OnlineStoreMinor,
      },
      {
        name: 'Draft List',
        to: '/order/draft/list',
        icon: NoteMinor,
      },
    ],
  },
  {
    name: 'Marketing',
    to: '#',
    header: true,
    subs: [
      {
        name: 'Event Campaign',
        to: '/marketing/list',
        icon: DiscountsMinor,
      },
    ],
  },
  {
    name: 'Customer Service',
    to: '#',
    header: true,
    subs: [
      {
        name: 'Order History',
        to: '/order/history',
        icon: OrderStatusMinor,
      },
    ],
  },
  {
    name: 'Manage Sellers',
    to: '#',
    header: true,
    subs: [
      {
        name: 'Manage Sellers',
        to: '/setting/seller',
        // icon: 'os-icon os-icon-user-male-circle',
        icon: StoreMinor,
      },
      {
        name: 'Top Up',
        to: '/setting/advertising',
        icon: MoneyMinor,
        subs: [
          {
            name: 'Advertising Fee',
            to: '/setting/advertising',
          },
          // {
          //   name: 'Affiliate Fee',
          //   to: '#',
          // },
        ],
      },
    ],
  },
];
